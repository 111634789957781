import _objectSpread from "D:/CODE/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import fixedAssetsA from "./components/fixed-assets-a";
import fixedAssetsB from "./components/fixed-assets-b";
import fixedAssetsC from "./components/fixed-assets-c";
import fixedAssetsD from "./components/fixed-assets-d";
import fixedAssetsE from "./components/fixed-assets-e";
import fixedAssetsF from "./components/fixed-assets-f";
import fixedAssetsG from "./components/fixed-assets-g";
import commonRoundSwiper1 from "../../../components/common-round-swiper1"; // import commonSwiper from "@/components/common-swiper";

import CommonFooter1 from "@/components/common-footer1";
import { mapMutations } from "vuex"; // import TDK from "/public/data/TDK.js";

export default {
  // metaInfo: {
  //   title: "固定资产管理方案", // set a title
  //   meta: [
  //     {
  //       // set meta
  //       name: "keyWords",
  //       content:
  //         "RFID固定资产管理系统,RFID固定资产管理方案,物联网资产管方案,RFID电子标签资产管理方案,常达,rfid物资管理系统,rfid资产盘点,rfid管理系统,rfid管理软件,rfid资产盘点系统,rfid资产盘点,rfid资产管理,rfid资产管理系统,RFID盘点,rfid固定资产,rfid固定资产盘点系统,医院rfid资产管理",
  //     },
  //     {
  //       name: "description",
  //       content:
  //         "企业的固定资产种类繁多， 很多时候因资源太锁碎多变往往忽略其成本控制和财务统计的重要性。 常达智能可通过超高频RFID设备， 结合前端人工数据收集和后台计算大数据库， 促使企业轻松记录， 实时监察资源和记录， 充分体现“ 帐、 卡、 物” 数据相符的资产管理系统。",
  //     },
  //   ],
  // },
  components: {
    CommonFooter1: CommonFooter1,
    top: top,
    fixedAssetsA: fixedAssetsA,
    fixedAssetsB: fixedAssetsB,
    fixedAssetsC: fixedAssetsC,
    fixedAssetsD: fixedAssetsD,
    fixedAssetsE: fixedAssetsE,
    fixedAssetsF: fixedAssetsF,
    fixedAssetsG: fixedAssetsG,
    commonRoundSwiper1: commonRoundSwiper1 // commonSwiper,

  },
  data: function data() {
    return {
      // theme: {
      //   color: "#2592C6",
      //   borderColor: "#2592C6",
      // },
      swiperList: [{
        t: "信息采集",
        c: "工作人员对所有指定资产配上一个RFID电子标签，同时将资产信息写入RFID电子标签，并将信息通过相应的软件存入服务器以作识别之用。",
        icon: require("@/assets/fixed-assets/1.png"),
        icon2: require("@/assets/fixed-assets/1c.png")
      }, {
        t: "资产盘点",
        c: "盘点资产时，只需持手持式RFID阅读器就可读取到资产上的RFID电子标签信息，同时通过通讯接口将所有读取到的信息传给服务器，服务器通过应用软件在对这些信息进行相应的处理。",
        icon: require("@/assets/fixed-assets/2.png"),
        icon2: require("@/assets/fixed-assets/2c.png")
      }, {
        t: "资产转移",
        c: "在每栋大楼的关键出入口布置一台固定式RFID读写器，当资产被带离大楼时，RFID资产管理系统会查验调拨记录，如果未经授权调拨资产，报警器提示管理员核查，如果是经授权调拨资产，系统会自动记录出行时间，并对资产的数量进行便捷的统计。",
        icon: require("@/assets/fixed-assets/3.png"),
        icon2: require("@/assets/fixed-assets/3c.png")
      }, {
        t: "资产轨迹",
        c: "根据现场情况，合理布置2.4G阅读器，监读资产轨迹，实时了解资产位置，并可对非法移动的资产报警。",
        icon: require("@/assets/fixed-assets/4.png"),
        icon2: require("@/assets/fixed-assets/4c.png")
      }],
      cList: [{
        t: "一物一码",
        c: "一个二维码代表一个资产，简单唯一",
        img: require("@/assets/fixed-assets/swiper/1.png"),
        icon: require("@/assets/fixed-assets/swiper/icon1.png")
      }, {
        t: "轻松盘点",
        c: "盘点任务细化给每个员工，员工可以通过手机扫描盘点自己名下的资产",
        img: require("@/assets/fixed-assets/swiper/4.png"),
        icon: require("@/assets/fixed-assets/swiper/icon2.png")
      }, {
        t: "帐实统一",
        c: "账面与实物，一一对应，数据统一",
        img: require("@/assets/fixed-assets/swiper/3.png"),
        icon: require("@/assets/fixed-assets/swiper/icon3.png")
      }, {
        t: "员工自盘",
        c: "使用微信小程序，扫描二维码，即可盘点",
        img: require("@/assets/fixed-assets/swiper/2.png"),
        icon: require("@/assets/fixed-assets/swiper/icon4.png")
      }, {
        t: "资产分析",
        c: "通过系统里的资产信息，盘点情况，使用情况，进行资产维护、变更、概况等分析",
        img: require("@/assets/fixed-assets/swiper/5.png"),
        icon: require("@/assets/fixed-assets/swiper/icon5.png")
      }, {
        t: "高性价比",
        c: "最大程度的提升管理效率，避免不必要的投资浪费",
        img: require("@/assets/fixed-assets/swiper/6.png"),
        icon: require("@/assets/fixed-assets/swiper/icon6.png")
      }],
      cList1: [{
        t: "批量盘点",
        c: "利用无线通讯、远距离传输，一次性盘点多个资产",
        img: require("@/assets/fixed-assets/swiper/22.png"),
        icon: require("@/assets/fixed-assets/swiper/icon22.png")
      }, {
        t: "唯一ID",
        t1: "唯一资产ID",
        c: "RFID标签与资产绑定，每个资产有全球唯一ID",
        img: require("@/assets/fixed-assets/swiper/11.png"),
        icon: require("@/assets/fixed-assets/swiper/icon11.png")
      }, {
        t: "生命周期",
        c: "利用资产管理系统，管理资产从申购，到使用、变更，直至报废的全生命周期",
        img: require("@/assets/fixed-assets/swiper/33.png"),
        icon: require("@/assets/fixed-assets/swiper/icon33.png")
      }, {
        t: "分析报表",
        c: "根据采集到的信息，分析资产概况，盘点、轨迹、维护等",
        img: require("@/assets/fixed-assets/swiper/44.png"),
        icon: require("@/assets/fixed-assets/swiper/icon44.png")
      }, {
        t: "资产轨迹",
        c: "主动和被动，采集资产轨迹，管理资产使用和位置追溯",
        img: require("@/assets/fixed-assets/swiper/55.png"),
        icon: require("@/assets/fixed-assets/swiper/icon55.png")
      }, {
        t: "资产查找",
        c: "利用RFID技术，精准查找资产，保持账实相符",
        img: require("@/assets/fixed-assets/swiper/66.png"),
        icon: require("@/assets/fixed-assets/swiper/icon66.png")
      }],
      d1: {
        t: "资产管理系统",
        t1: "二维码版",
        c: "遵循低成本、高效益的原则，系统结合PC端与微信小程序，借助二维码实现办公室环境下的固定资产快速管理。"
      },
      d2: {
        t: "资产管理系统",
        t1: "RFID版",
        c: "采用无线射频识别技术(RFID)以及条码自动识别技术，结合前端人工数据收集和后台计算大数据库，能够从资产的申购、入库、申领、盘点、维修、报废等环节全程跟踪，实现固定资产的全生命周期管理。"
      },
      recommend: {
        title: "资产管理产品推荐",
        list: [{
          image: require("@/assets/fixed-assets/recommend/1.png"),
          to: "/product?name=RFID柔性/抗金属标签&current=2"
        }, {
          image: require("@/assets/fixed-assets/recommend/2.png"),
          to: "/product?name=通道门"
        }, {
          image: require("@/assets/fixed-assets/recommend/3.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/fixed-assets/recommend/4.png"),
          to: "/product?name=四通道读写器&current=4"
        }, {
          image: require("@/assets/fixed-assets/recommend/5.png"),
          to: "/product?name=桌面式读写器&current=1"
        }]
      }
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    // TDK.setTDK("fixed-assets");
    // this.setHeaderColorWhite();
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
  },
  unmounted: function unmounted() {// this.setHeaderColorBlack();
  }
};